@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif; */
  font-family: "Nunito", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.st-cmp-v2 {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.box-size {
  max-width: 600px !important;
}

.box-size-2 {
  max-width: 1020 !important;
}

.container-design {
  background-color: #efefef;
  padding: 25px;
  border-radius: 10px;
}

.card-main {
  justify-content: center;
  text-align: center;
  align-items: center;
  /* color: rgb(0, 0, 0); */
  font-weight: 800;
  border: none;
  text-transform: uppercase;
  height: 70px !important;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 5px;
}

.card-main .card-body {
  line-height: 15px !important;
  display: flex;
  align-items: center;
}

.group-success {
  text-align: center;
}

.game-group {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: center;
}

.bg-yellow { background-color: rgb(251, 212, 0); }
.bg-green { background-color: rgb(181, 227, 82); }
.bg-blue { background-color: rgb(114, 158, 235); }
.bg-purple { background-color: rgb(188, 112, 196); }

.bg-1 { background-color: #fbd400; }
.bg-2 { background-color: #b5e352; }
.bg-3 { background-color: #729eeb; }
.bg-4 { background-color: #bc70c4; }

.completed-hide {
  display: none;
}
.congratulations {
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  margin: 20px 0;
}
.congratulations .share {
  margin-top: 15px;
}

.congratulations .share-row {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; /* Spacing between rows */
}

.congratulations .share-row span {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  display: inline-block;
  margin: 0 5px; /* Horizontal spacing */
  padding: 5px; /* Add padding */
  border-radius: 50%; /* Optional, for circular appearance */
}

.how-to-play-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.how-to-play-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.how-to-play-card .card-body {
  transition: background-color 0.3s ease;
}

.how-to-play-card:hover .card-body {
  background-color: #f8f9fa;
}

/* KeyFeatures.css */

/* General Styles */
.key-features-section {
  background-color: #f9f9f9;
}

.section-title {
  font-size: 2.1rem;
  font-weight: 900;
  color: #343a40;
}
.fw-900 {
  font-size: 1.9rem;
  font-weight: 800;
}

.section-subtitle {
  font-size: 1.2rem;
  color: #6c757d;
  max-width: 700px;
  margin: 0 auto;
}

/* Feature Card Styles */
.feature-card {
  text-align: center;
  border: none;
  background-color: #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding-top: 30px;
  border-radius: 10px;
  position: relative;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.icon-wrapper {
  width: 80px;
  height: 80px;
  background-color: #007bff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 20px auto;
  font-size: 2rem;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
}

.feature-card .card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #343a40;
  margin-top: 40px;
}

.feature-card .card-text {
  color: #6c757d;
  font-size: 1rem;
  margin-top: 15px;
}

/* Conclusion Section Styles */
.conclusion-section {
  background-color: #ffffff;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
}

.conclusion-section h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 20px;
}

.conclusion-section p {
  font-size: 1.1rem;
  color: #6c757d;
  max-width: 800px;
  margin: 0 auto 30px auto;
}

.faq-section {
  padding: 2rem;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  max-width: 800px;
  margin: 30px auto;
}

.faq-heading {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
}

.faq-card {
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.accordion-button {
  background-color: #e0e0e0;
  color: #fff;
  border: none;
}

.accordion-button:not(.collapsed) {
  background-color: #e0e0e0;
  color: #fff;
}

.accordion-body {
  background-color: #fff;
  color: #333;
}

.about-us {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 5px;
}

.about-us h1 {
  font-size: 2.5rem;
  color: #343a40;
}

.about-us p {
  font-size: 1.1rem;
  color: #495057;
}

.about-us a {
  color: #007bff;
  text-decoration: none;
}

.about-us a:hover {
  text-decoration: underline;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  /* Center vertically */
}

.spinner {
  border: 8px solid #f3f3f3;
  /* Light gray background */
  border-top: 8px solid #3498db;
  /* Blue color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
}

.privacy-policy {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background-color: #efefef;
  border-radius: 10px;
}

.privacy-policy h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-policy h3 {
  font-size: 1.5rem;
  margin-top: 15px;
}

.privacy-policy p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

.contact-us {
  margin: 20px auto;
  max-width: 900px;
  padding: 20px;
}

.contact-us h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-us h2 {
  font-size: 2rem;
  margin-top: 20px;
}

.contact-us p {
  font-size: 1rem;
  line-height: 1.6;
}

.contact-us ul {
  list-style-type: none;
  padding: 0;
}

.contact-us li {
  margin-bottom: 10px;
}

.contact-us .alert {
  margin-bottom: 20px;
}

.row.rowP>* {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.cardMainDesign {
  box-shadow: 0px -4.04px 58.75px 2.69px #FFFFFF40;
  border-radius: 25px;
  background-color: #f000;
  margin: 15px auto !important;

}

.blog-detail {
  margin-top: 80px;
  margin-bottom: 80px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  padding: 50px 80px !important;
}

.blog-detail img.img_banner {
  text-align: center;
  margin: 25px auto;
  width: 100%;
  border-radius: 50px;
}

.author {
  align-items: center;
  color: var(--themeColor2);
  display: flex;
  line-height: 1.4;
  margin: auto;
  padding: 1rem;
  font-weight: 600;

}

.author a {
  color: var(--themeColor2);
  font-weight: 600;
}

.author__avatar {
  border-radius: 50%;
  display: block;
  height: 4rem;
  margin-right: 1rem;
  width: 4rem;
}

.toc {
  margin: 30px auto;
  padding: 20px;
  background-color: #dfdfdfb7;
  border-radius: 20px;
  cursor: pointer;
}

.toc-header svg {
  margin: 0 5px;
}

.toc h4 {
  margin-bottom: 0px;
}

.toc ul {
  list-style-type: none;
  padding: 10px 40px;
}

.toc-item.h2 a {
  font-weight: bold;
  color: #2b2b2b;
  text-decoration: none;
}

.toc-item.h3 a {
  padding-left: 10px;
  font-weight: normal;
  color: #2b2b2b;
  text-decoration: none;
}

.toc-item.h2 {
  font-size: 20px;
}

.toc-item.h3 {
  font-size: 16px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .start-card-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .card-main {
    font-size: 12px;
  }

  .card-body {
    padding: 8px !important;
  }

  .box-size-2 {
    max-width: 600px !important;
  }
}

/* new style design 09-12-2024 */

.game-container {
  text-align: center;
  background-color: white;
  border-radius: 10px;
  margin: auto;
  width: 624px;
  max-width: 800px;
  margin-top: 20px;
  min-height: 569px;
  /* font-family: "Agbalumo", system-ui; */
}

#grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  margin: 20px 0;
  /* font-family: "Agbalumo", system-ui; */
}

.word {
  background-color: #efefe6;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-transform: uppercase;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 150px;
  height: 80px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.word.selected {
  background-color: #555555;
  color: #ffffff;
}

.word.shake {
  animation: shake-animation 0.5s ease;
}

@keyframes shake-animation {

  0%,
  100% {
    transform: translateX(0);
  }

  20%,
  60% {
    transform: translateX(-4px);
  }

  40%,
  80% {
    transform: translateX(4px);
  }
}


.shuffle {
  width: 160px;
  height: 50px;
  border: 1px solid black !important;
  border-radius: 25px !important;
  background-color: transparent !important;
  color: black !important;
  font-size: 1em !important;
  margin: 0.5em 0 !important;
  cursor: pointer !important;
  /* font-family: "Nunito", sans-serif; */
}

.deselectAll {
  width: 160px;
  height: 50px;
  border: 1px solid black !important;
  border-radius: 25px !important;
  background-color: transparent !important;
  color: black !important;
  font-size: 1em !important;
  margin: 0.5em 0 !important;
  cursor: pointer !important;
  /* font-family: "Nunito", sans-serif; */
}

.checkConnections {
  width: 160px;
  height: 50px;
  border: 1px solid #cd87ff !important;
  border-radius: 25px !important;
  background-color: #cd87ff !important;
  color: black !important;
  font-size: 1em !important;
  margin: 0.5em 0 !important;
  cursor: pointer !important;
  transition: background-color 0.5s ease-in;
  /* font-family: "Nunito", sans-serif; */
}

.checkConnections:hover {
  background-color: #c77aff !important;
  border: 1px solid #c77aff !important;
  transition: background-color 0.5s ease-in;

}

.checkConnections:active,
.deselectAll:active,
.shuffle:active {
  transition: all .1s ease-in-out;
  transform: scale(0.92);
}

.mistake-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #888;
  border-radius: 50%;
  margin: 0 2px;
}

.mistake-dot.used {
  background-color: red;
}

.archive-btn,
#dropdown-basic {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  height: 40px;
  font-size: 14px;
}

.nav-menu-color {
  text-decoration: none;
  color: #000;
}

.nav-menu-color:hover {
  color: #5b15ea;
}

.create {
  background: linear-gradient(48deg, #f1bbf2ff, #f3c4eeff, #f5cce9ff, #f9ddebff, #f9dfe7ff, #fae4dfff, #fbe7dbff, #fbe9d7ff);
  /* background-size: 500% 400%; */
  margin-top: -5px;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  color: #000;
  transition: all .3s ease;
}

.create:hover {
  background: linear-gradient(148deg, #f1bbf2ff, #f3c4eeff, #f5cce9ff, #f9ddebff, #f9dfe7ff, #fae4dfff, #fbe7dbff, #fbe9d7ff);

}

.logo-text {
  text-decoration: none;
  font-family: "Agbalumo", system-ui;
}

.archive-table .archive-tbody {
  background-color: #f7fafc !important;
}

.archive-table thead tr th {
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  font-size: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 200px;
  text-align: left;
}

.archive-table .archive-tbody td {
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  font-size: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 200px;
  text-align: left;
}

.archive-table .archive-tbody tr {
  border-bottom: 1px solid #efefef;
  cursor: pointer;
}

.archive-table .archive-tbody tr:hover {
  border-bottom: 1px solid #cecece;
}

.archive-table .archive-tbody tr.solved {
  background: #C6F6D5 !important;
}

.archive-table .archive-tbody td svg {
  width: 20px;
  height: 20px;
  padding: 0.25rem;
  border-radius: 9999px;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: #ffffff;
  vertical-align: middle;
  background: #9E9E9E;
  margin-left: 0.5rem;
  margin-bottom: 0.25rem;
}

.archive-table .archive-tbody tr.solved td svg {
  background: #38a169 !important;
}

/* Main container styles */
.social-share-buttons {
  transition: all 0.2s ease-in;
  backface-visibility: hidden;
  display: grid;
  position: fixed;
  opacity: 1;
  text-align: left;
  top: 180px;
  z-index: 94034;
  /* background-color: #e0e0e0; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bg-color {
  display: grid;
  background: #e0e0e0;
  padding: 0 5px;
}

.reddit-share {
  background-color: transparent;
}

.share-text {
  font-size: 12px;
  margin-bottom: 0px;
}

/* Share button styles */
.social-share-buttons button {
  box-sizing: border-box;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 35px;
  line-height: 20px;
  margin-bottom: 0;
  opacity: 1;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: left;
  top: 0;
  vertical-align: top;
  white-space: nowrap;
  /* width: 40px; */
  /* background-color: white;  */
  /* border-radius: 50%;  */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
}

/* Share button icon styles */
.social-share-buttons button svg {
  fill: black;
  /* Icon color */
  width: 100%;
  display: block;
  color: black;
  /* Icon color black */
  fill: black;
  /* For FontAwesome icons */
  transition: color 0.2s ease-in;
}

/* Hover effect */
.social-share-buttons button:hover {
  /* background-color: #f0f0f0; */
  transform: scale(1.1);
  /* Slight zoom-in on hover */
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.3);
  /* Enhanced shadow on hover */
}

.btn-difficulty {
  padding: 10px 20px !important;
  font-size: 16px !important;
  background-color: #ddd !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #000 !important;
  font-family: "Nunito", sans-serif !important;
}

.btn-difficulty.active,.btn-difficulty:hover {
  background-color: #bbb !important;
}

.words-card {
  cursor: move;
  /* Indicate draggable behavior */
  position: relative;
  /* Ensure they are positioned */
}

.st-cmp-settings {
  display: none !important;
}

.create-div-home {
  padding: 70px 0;
  background-color: #fbf9f5;
}
.creat-btn-home {
  background-color: #41a7fb !important;
  color: #fff !important;
}
.bg-black {
  background-color: rgb(0, 0, 0) !important;
  color: #fff !important;
}

@media only screen and (max-width: 600px) {
  .start-card-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .card-main {
    font-size: 12px;
  }

  .card-body {
    padding: 8px !important;
  }

  .box-size-2 {
    max-width: 600px !important;
  }
  .blog-detail {
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 0px;
    padding: 10px 10px !important;
  }
  .author__avatar {
    height: 2rem;
    width: 2rem;
  }

}

@media screen and (min-width: 0em) and (max-width: 47.98em) {
  .letter-15 {
    font-size: 12px !important;
  }

  .letter-14 {
    font-size: 12px !important;
  }

  .letter-13 {
    font-size: 12px !important;
  }

  .letter-12 {
    font-size: 12px !important;
  }

  .letter-11 {
    font-size: 12px !important;
  }

  .letter-10 {
    font-size: 12px !important;
  }

  .letter-9 {
    font-size: 12px !important;
  }

  .letter-8 {
    font-size: 13px !important;
  }

  .game-container {
    width: 100%;
  }

  #grid {
    padding: 0px 10px 0px 15px;
  }

  .word {
    width: 100%;
    height: 16vw;
    font-size: 0.875rem;
    line-height: 1.2;
  }

  .share-text {
    display: none;
    margin-top: 25px !important;
  }

  .social-share-buttons {
    transition: all 0.2s ease-in;
    backface-visibility: hidden;
    display: block;
    position: fixed;
    opacity: 1;
    text-align: left;
    top: auto;
    bottom: 0;
    z-index: 94034;
    /* background-color: #e0e0e0; */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
  }

  .bg-color {
    display: flex;
    background: #e0e0e0;
    padding: 5px 5px;
    width: 100%;
    justify-content: center;
  }

  .bg-color .react-share-button {
    margin: 0 15px;
    width: 50px !important;
  }

  .time-div {
    margin-top: 15px;
    padding-left: 15px;
  }

}